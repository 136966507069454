<template>
  <b-modal
      :id="modalUUid"
      :ref="modalUUid"
      title="Live Query Surveys"
      size="lg"
      hide-footer
  >
    <div>
      <b-form class="mb-1" @submit.prevent="requestSurveysLive">
        <b-row class="align-items-center">
          <b-col cols="4" class="d-flex align-items-center">
            <label class="mr-2 mb-0 font-small-4">Device</label>
            <b-form-input v-model="liveQuery.device" />
          </b-col>

          <b-col cols="4" class="d-flex align-items-center">
            <label class="mr-2 mb-0 font-small-4">IP</label>
            <b-form-input v-model="liveQuery.ip" />
          </b-col>

          <b-col cols="4" class="d-flex align-items-center">
            <b-form-checkbox v-model="liveQuery.ignoreDisqualificationCache" class="mb-0">
              Ignore Disqualification Cache
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="align-items-center my-1">
          <b-col cols="6" class="d-flex align-items-center">
            <label class="mr-2 mb-0 font-small-4 w-25">User Agent</label>
            <b-form-input v-model="liveQuery.user_agent" />
          </b-col>
          <b-col cols="1"></b-col>

          <b-col cols="5" class="d-flex align-items-center">
            <b-form-radio-group v-model="liveQuery.endpoint" name="endpoint">
              <b-form-radio value="default">Default Endpoint</b-form-radio>
              <b-form-radio value="partners">Partners Endpoint</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <b-button variant="primary" type="submit">Request Surveys Live</b-button>
        <b-button variant="link" @click="showRawQueryModal">View Query</b-button>

        <b-button
            v-if="requestDetails && requestDetails.curl_command"
            class="copy-button position-absolute position-right-0 mr-3 mt-1"
            variant="outline-primary"
            @click="copyRawToClipboard(requestDetails.curl_command)"
        >
          Copy CURL Request
        </b-button>
      </b-form>

      <b-spinner
          v-if="loading"
          label="Loading..."
          variant="primary"
          class="my-4 d-block mx-auto"
      ></b-spinner>

      <div v-if="!loading && surveyResult" class="w-25 border mb-2">
        <div v-if="hasEligibleSurveys" class="py-50 text-center">
          {{ surveyResult.queried_surveys }} surveys returned <br />
          <hr class="my-25" />
          {{ surveyResult.checked_surveys }} surveys checked <br />
          - {{ surveyResult.ineligible_surveys_count }} surveys ineligible <br />
          = <b>{{ surveyResult.eligible_surveys_count }} surveys eligible </b>
        </div>
        <div v-else class="text-center text-danger font-weight-bold px-4 py-2">
          Error
        </div>
      </div>

      <b-table
          v-if="!loading && (hasEligibleSurveys || !surveyResult)"
          :items="formattedSurveyResults"
          :fields="tableFields"
          bordered
          responsive
          striped
          sort-by="score"
          :sort-desc="true"
      >
        <template #cell(survey)="data">
          <SurveyElement :id="data.value" :status="data.item.status" :visibility="data.item.visibility" />
        </template>
      </b-table>

      <div v-if="!loading && !hasEligibleSurveys && surveyResult" class="p-3 bg-light rounded">
        <h5 class="text-center">Response JSON</h5>
        <pre>{{ filteredSurveyResult }}</pre>
      </div>
    </div>

    <!-- Raw Query Modal -->
    <b-modal :id="rawQueryModalUUid" :ref="rawQueryModalUUid" title="Raw Query" size="lg" hide-footer>
      <div class="query-container">
        <div v-for="(query, index) in formattedQueries" :key="index">
          <b-button
              class="copy-button position-absolute position-right-0 mr-3 mt-1"
              variant="outline-primary"
              @click="copyRawToClipboard(query)"
          >
            Copy Query
          </b-button>
          <pre class="p-2">{{ query }}</pre>
        </div>
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
import SurveyElement from '@/views/elements/SurveyElement.vue'
import { format } from 'sql-formatter'
export default {
  components: {SurveyElement},
  props: {
    user: {
      type: Object,
      required: true
    },
    userId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      modalUUid: `liveQuerySurveysModal${this.userId}`,
      rawQueryModalUUid: `rawQueryModal${this.userId}`,
      liveQuery: {
        device: '',
        ip: '',
        user_agent: '',
        ignoreDisqualificationCache: false,
        endpoint: 'default'
      },
      surveyResult: null,
      rawQuery: [],
      requestDetails: {},
      tableFields: [
        { key: 'score', label: 'Score' },
        { key: 'survey', label: 'Survey' },
        {
          key: 'result',
          label: 'Result',
          tdClass: (value) => {
            return value === 'Eligible' ? 'text-success' : 'text-danger'
          }
        },
        { key: 'ineligibleReason', label: 'Ineligible Reason' }
      ]
    }
  },
  computed: {
    hasEligibleSurveys () {
      if (!this.surveyResult) return false

      return Array.isArray(this.surveyResult.eligible_surveys)
    },
    formattedSurveyResults () {
      if (!this.surveyResult) return []

      const eligibleSurveys = (this.surveyResult.eligible_surveys || []).map(survey => ({
        score: survey.score ?? 0, // Default score to 0 if missing
        survey: survey.survey_id, // Use the survey_id property from the API
        status: survey.status,
        visibility: Number(survey.visibility),
        result: 'Eligible',
        ineligibleReason: '' // No reasons for eligible surveys
      }))

      const ineligibleSurveys = Object.values(this.surveyResult.ineligible_surveys || {}).map(survey => ({
        score: survey.score ?? 0, // Default score to 0 if missing
        survey: survey.survey_id, // Use the survey_id property from the API
        status: survey.status,
        visibility: Number(survey.visibility),
        result: 'Ineligible',
        ineligibleReason: survey.reasons?.join(', ') || 'Unknown' // Join reasons or use 'Unknown'
      }))

      return [...eligibleSurveys, ...ineligibleSurveys]
    },
    filteredSurveyResult () {
      if (!this.surveyResult) return {}
      // eslint-disable-next-line no-unused-vars
      const { queries, ...rest } = this.surveyResult
      return rest
    },
    formattedQueries () {
      if (!this.rawQuery) {
        return []
      }

      return this.rawQuery.map((query) => format(query))
    }
  },
  methods: {
    copyRawToClipboard (data) {
      window.navigator.clipboard.writeText(data)
        .then(() => {
          this.alertSuccess('Copied successfully')
        })
        .catch((err) => {
          this.alertError('Failed to copy to clipboard. Please try again.')
          console.error(err)
        })
    },
    showModal () {
      // Prefill from user data
      this.liveQuery.device = this.user.device
      this.liveQuery.ip = this.user.last_ip

      if (this.user.last_click) {
        this.liveQuery.user_agent = this.user.last_click.user_agent
      }
      this.$bvModal.show(this.modalUUid)
    },
    showRawQueryModal () {
      this.$bvModal.show(this.rawQueryModalUUid)
    },
    requestSurveysLive () {
      this.loading = true

      const params = {
        ...this.liveQuery,
        ignore_disqualification_cache: Number(this.liveQuery.ignoreDisqualificationCache)
      }
      delete params.ignoreDisqualificationCache

      this.$http.get(`/api/user/${this.userId}/live-query-surveys`, {params}).then(response => {
        this.surveyResult = response.data
        this.rawQuery = response.data.queries
        this.requestDetails = response.data.request_details

        this.alertSuccess('Live Query fetched successfully.')
      }).catch(errors => {
        this.surveyResult = null
        this.rawQuery = []
        this.requestDetails = {}

        if (typeof errors.response === 'object') {
          if (typeof errors.response.data.errors === 'object') {
            this.alertError(errors.response.data.errors)
          } else {
            this.alertError(errors.response.data)
          }
        } else {
          this.alertError(errors.message)
        }
      })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
