<template>
  <div class="ps-tooltip-parent">
    <div :id="tooltipTargetId" @mouseover="canAccess('survey_details') && !surveyDetail ? getBasicDetail() : ''" class="survey-item">
      <div class="survey-id">
        <router-link
            v-if="canAccess('survey_details')"
            :to="{
                name: 'survey_details',
                params: { surveyId: id },
            }"
        >
          {{ id }}
        </router-link>
        <span v-else>{{ id }}</span>
      </div>
      <div v-if="status >= 0" :class="`survey-status-visibility survey-status-${status} survey-visibility-${visibility}`"></div>
    </div>

    <b-tooltip v-if="canAccess('survey_details')" :target="tooltipTargetId" :variant="$store.state.appConfig.layout.skin" triggers="hover" placement="bottomright">
      <div class="width-350 font-small-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="card p-2 mb-0">
              <div class="d-flex justify-content-between">
                <b-skeleton width="30%"></b-skeleton>
                <b-skeleton width="40%"></b-skeleton>
              </div>
              <b-skeleton width="40%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>

              <b-skeleton class="mt-3" width="50%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
            </div>
          </template>

          <b-row
              v-if="surveyDetail"
              class="card border border-dark p-2 mb-0 text-left"
              style="top: -5px;"
              :style="$store.state.appConfig.layout.skin === 'dark' ? 'border-color: white !important' : ''"
          >
            <b-col class="d-flex justify-content-between" :class="surveyDetail.survey.visibility ? 'mb-1' : 'mb-50'">
              <div class="survey-item">
                <div class="survey-id">
                  <router-link
                      v-if="canAccess('survey_details')"
                      :to="{
                            name: 'survey_details',
                            params: { surveyId: id },
                        }"
                  >
                    {{ id }}
                  </router-link>
                  <span v-else>{{ id }}</span>
                </div>
                <div :class="`survey-status-visibility survey-status-${surveyDetail.survey.status} survey-visibility-${surveyDetail.survey.visibility}`"></div>
              </div>

              <div><b>Created</b>: {{ surveyDetail.survey.created_at_timeago }} </div>
            </b-col>

            <b-col v-if="!surveyDetail.survey.visibility" class="mb-1 text-warning text-capitalize">
              {{ JSON.parse(surveyDetail.survey.reasons).join(', ') }}
            </b-col>

            <b-col class="mb-1">
              <div class="mb-50">
                <CountryElement :name="surveyDetail.survey.country_name" :code="surveyDetail.survey.country_code" />
              </div>
              <div class="row">
                <div class="col-4 text-nowrap">
                  <SupplierElement :id="surveyDetail.survey.supplier_id" :name="surveyDetail.survey.supplier_name" />
                </div>
                <div v-if="surveyDetail.supplier_buyer" class="col-8">
                  <BuyerElement
                      :id="surveyDetail.supplier_buyer.id"
                      :buyerId="surveyDetail.supplier_buyer.supplier_buyer_id"
                      :name="surveyDetail.supplier_buyer.supplier_buyer_name"
                  />
                </div>
                <div v-else class="col-8">{{ surveyDetail.survey.buyer_id }}</div>
              </div>
            </b-col>

            <b-col>
              <b-row>
                <b-col cols="4" class="mb-50"><b>Revenue:</b></b-col>
                <b-col cols="8">
                  <b>{{ surveyDetail.survey.revenue | formatCurrency }}</b>
                </b-col>

                <b-col cols="4" class="mb-50">Clicks:</b-col>
                <b-col cols="7">{{ surveyDetail.performance.clicks_count | formatCount }}</b-col>

                <b-col cols="4" class="mb-50">Completes:</b-col>
                <b-col cols="7">
                  <b>{{ surveyDetail.performance.completes_count | formatCount }}</b> ({{ surveyDetail.performance.completes_percentage | percentage }})
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-skeleton-wrapper>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
import BuyerElement from '@/views/elements/BuyerElement.vue'

export default {
  components: {BuyerElement},
  props: {
    id: {
      type: Number,
      required: true
    },
    status: {
      type: Number
    },
    visibility: {
      type: Number
    }
  },
  data () {
    return {
      loading: true,
      surveyDetail: null
    }
  },
  computed: {
    tooltipTargetId () {
      // Generate a unique ID based on id prop and an index
      return `survey-tooltip-target-${this.id}-${this._uid}`
    }
  },
  watch: {
    id: {
      handler () {
        if (this.surveyDetail) {
          this.loading = true
          this.surveyDetail = null
        }
      }
    }
  },
  methods: {
    getBasicDetail () {
      this.sendActionLog(`get survey basic detail from ${this.$route.path} with survey id ${this.id}`)

      if (!this.surveyDetail) {
        this.$http.get(
          `/api/surveys/${  this.id  }/basic`
        ).then((response) => {
          this.surveyDetail = response.data
          this.loading = false
        }).catch((errors) => {
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
          }
        })
      }
    }
  }
}
</script>


<style>
  .modal-dialog .tooltip.b-tooltip-light .tooltip-inner,
  .modal-dialog .tooltip.b-tooltip-dark .tooltip-inner {
    background: transparent !important;
  }
</style>
