export default {
  namespaced: 'chat',
  state: {
    timerInterval: null,
    dateNow: new Date(),
    conversations: [],
    selectedConversations: [],
    resetSelectedConversations: false,
    conversationsLoading: false,
    conversationsPage: 1,
    conversationsPerPage: 30,
    conversationsLastPage: 2,
    assigneeFilterOptions: [],
    participantFilterOptions: [],
    sourcesFilterOptions: [],
    currentConversation: null,
    currentConversationLoading: false,
    userInfo: null,
    userInfoLoading: false,
    userDetailsInfo: null,
    intercomBanned: null,
    streaks: [],
    streakLastRenewal: null,
    currentStreakId: null,
    messages: [],
    messagesLoading: false,
    messagesPage: 1,
    messagesPerPage: 20,
    messagesLastPage: 2,
    messageLoading: false,
    socketMessageLoading: false,
    sort: 'newest',
    filterOptions: {
      status: [],
      assignee_user_ids: [],
      participant_user_ids: [],
      app_external_id: [],
      sources: [],
      user_id: '',
      start_time: null,
      rating: []
    },
    conversationType: 0,
    locales: [],
    countries: [],
    selectedCountries: [],
    isSelectedCountriesUpdated: false,
    isMacroUpdated: false,
    macrosListMostlyUsed: [],
    macrosListAll: [],
    adminStatus: 0,
    queuedCount: 0,
    onlineAssignees: [],
    assigneesModalType: 'single', // 'single' || 'multiple'
    snoozeModalType: 'single', // 'single' || 'multiple'
    notificationSound: '/notification.mp3',
    storageUrl: process.env.VUE_APP_CDN_DOMAIN,
    currentlyPlayingAudio: null,
    notes: [],
    notesLoading: false,
    noteLoading: false,
    notesSort: 'newest',
    recentConversations: [],
    recentConversationsLoading: false,
    recentConversationsPage: 1,
    recentConversationsLastPage: 2,
    isSupportTl: false,
    userQueues: {
      is_chat_queue_enabled: false,
      is_ticket_queue_enabled: false
    },
    userQueuesLoading: false,
    isNotMatchActiveFilters: false,
    shouldRefreshDashboardTable: false
  },
  getters: {
    hasFilterOptions (state) {
      return state.filterOptions.status.length
                || state.filterOptions.assignee_user_ids.length
                || state.filterOptions.participant_user_ids.length
                || state.filterOptions.app_external_id.length
                || state.filterOptions.sources.length
                || state.filterOptions.user_id
                || state.filterOptions.start_time
                || state.filterOptions.rating.length
    },
    isAppUser (state) {
      return state.currentConversation ? state.currentConversation.creator.is_app_user : false
    },
    userId (state) {
      return state.userInfo ? state.userInfo.user_id : null
    },
    appUserId (state) {
      return state.userInfo ? state.userInfo.app_user_id : null
    },
    appId (state) {
      return state.userInfo ? state.userInfo.app_id : null
    },
    isPO (state) {
      return state.userInfo ? state.userInfo.app_id === 13 : null
    },
    isFS (state) {
      return state.userInfo ? state.userInfo.app_id === 16 : null
    },
    isHC (state) {
      return state.userInfo ? state.userInfo.app_id === 18 : null
    },
    appName (state) {
      if (state.userInfo && state.userInfo.app_id === 13) {
        return 'prime-opinion'
      }
      if (state.userInfo && state.userInfo.app_id === 16) {
        return 'five-surveys'
      }
      if (state.userInfo && state.userInfo.app_id === 18) {
        return 'hey-cash'
      }
      return null
    },
    externalAppId (state) {
      return state.currentConversation ? state.currentConversation.creator.app_external_id : null
    },
    externalUserId (state) {
      return state.currentConversation ? state.currentConversation.creator.external_id : null
    },
    macrosList (state) {
      return [...state.macrosListMostlyUsed, ...state.macrosListAll]
    }
  },
  mutations: {
    ADD_CONVERSATIONS (state, value) {
      state.conversations.push(...value)
    },
    SET_SELECTED_CONVERSATIONS (state, value) {
      state.selectedConversations = value
    },
    async RESET_SELECTED_CONVERSATIONS (state) {
      state.selectedConversations = []
      state.resetSelectedConversations = true

      await this._vm.$nextTick()

      state.resetSelectedConversations = false
    },
    UPDATE_CONVERSATIONS (state, value) {
      let list = [...value, ...state.conversations]

      if (state.sort === 'oldest') {
        list = [...state.conversations, ...value]
      }

      state.conversations = list
        .filter((item1, i, self) => self.findIndex(item2 => (item2.uuid === item1.uuid)) === i)

      // Remove rest conversations
      const total = state.conversationsPage * state.conversationsPerPage
      const diff = state.conversations.length - total
      
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {

          if (state.sort === 'oldest') {
            state.conversations.unshift()
          } else {
            state.conversations.pop()
          }

        }
      }
    },
    SET_CONVERSATIONS_LOADING (state, value) {
      state.conversationsLoading = value
    },
    SET_ASSIGNEE_OPTIONS (state, value) {
      state.assigneeFilterOptions = value
    },
    SET_PARTICIPANT_OPTIONS (state, value) {
      state.participantFilterOptions = value
    },
    SET_SOURCES_OPTIONS (state, value) {
      state.sourcesFilterOptions = value
    },
    REMOVE_FILTER_OPTIONS (state) {
      state.assigneeFilterOptions = []
      state.participantFilterOptions = []
      state.sourcesFilterOptions = []
    },
    REMOVE_CONVERSATIONS (state) {
      state.conversations = []
    },
    SET_CONVERSATIONS_PAGE (state, value) {
      state.conversationsPage = value
    },
    SET_CONVERSATIONS_LAST_PAGE (state, value) {
      state.conversationsLastPage = value
    },
    SET_CURRENT_CONVERSATION (state, value) {
      state.currentConversation = value
    },
    SET_CURRENT_CONVERSATION_LOADING (state, value) {
      state.currentConversationLoading = value
    },
    SET_CURRENT_CONVERSATION_STATUS (state, value) {
      if (!state.currentConversation) return

      state.currentConversation.status = value
    },
    SET_USER_INFO (state, value) {
      state.userInfo = value
    },
    SET_USER_INFO_LOADING (state, value) {
      state.userInfoLoading = value
    },
    SET_USER_DETAILS_INFO (state, value) {
      state.userDetailsInfo = value
    },
    SET_USER_IP_LOCATION_CHECK (state, value) {
      state.userDetailsInfo.ip_location_check = value
    },
    SET_INTERCOM_BANNED (state, value) {
      state.intercomBanned = value
    },
    SET_STREAKS (state, value) {
      state.streaks = value
    },
    SET_STREAK_LAST_RENEWAL (state, value) {
      state.streakLastRenewal = value
    },
    SET_CURRENT_STREAK_ID (state, value) {
      state.currentStreakId = value
    },
    ADD_NEW_CONVERSATION_TO_LIST (state, value) {
      // Is sort by oldest and it's last page
      if (state.sort === 'oldest') {

        if (state.conversationsPage > state.conversationsLastPage) {
          state.conversations.push(value)

          state.conversations.sort((x, y) => new Date(x.latest_generic_message_sent_at) - new Date(y.latest_generic_message_sent_at))
        }

        return
      }

      // Is sort by newest
      state.conversations.push(value)

      state.conversations.sort((x, y) => new Date(y.latest_generic_message_sent_at) - new Date(x.latest_generic_message_sent_at))

      // Is not last page, remove last value
      if (state.conversationsLastPage > state.conversationsPage) {
        state.conversations.pop()
      }
    },
    SET_MESSAGES (state, value) {
      const list = [...value, ...state.messages]

      state.messages = list
        .filter((item1, i, self) => self.findIndex(item2 => (item2.uuid === item1.uuid)) === i)

    },
    REMOVE_MESSAGES (state) {
      state.messages = []
    },
    ADD_MESSAGE (state, value) {
      state.messages.push(value)
    },
    INDICATE_DELETED_MESSAGE (state, { conversationId, messageId }) {

      // Indicated deleted message in messages list
      const targetMessage = state.messages.find(
        (message) => message.uuid === messageId
      )

      if (targetMessage) {
        targetMessage.deleted_at = Date.now()
      }

      // Indicated deleted message in conversation list
      if (conversationId) {
        const targetConversation = state.conversations.find(
          (conversation) => conversation.uuid === conversationId
        )

        if (targetConversation && targetConversation.latest_message.uuid === messageId) {
          targetConversation.latest_message.deleted_at = Date.now()
        }
      }
    },
    SEEN_MESSAGE (state, value) {
      const targetMessage = state.messages.find(
        (message) => message.uuid === value.uuid
      )

      if (targetMessage) {
        targetMessage.status = 'seen'
      }
    },
    UPDATE_LAST_MESSAGE (state, value) {
      state.messages.pop()
      state.messages.push(value)
    },
    SET_MESSAGES_LOADING (state, value) {
      state.messagesLoading = value
    },
    SET_MESSAGES_PAGE (state, value) {
      state.messagesPage = value
    },
    SET_MESSAGES_LAST_PAGE (state, value) {
      state.messagesLastPage = value
    },
    SET_MESSAGE_LOADING (state, value) {
      state.messageLoading = value
    },
    SET_SOCKET_MESSAGE_LOADING (state, value) {
      state.socketMessageLoading = value
    },
    SET_SORT (state, value) {
      state.sort = value
    },
    SET_FILTER_OPTIONS (state, value) {
      state.filterOptions = value

      if (!value.assignee_user_ids) {
        state.filterOptions.assignee_user_ids = []
      }

      if (!value.participant_user_ids) {
        state.filterOptions.participant_user_ids = []
      }

      if (!value.app_external_id) {
        state.filterOptions.app_external_id = []
      }

      if (!value.sources) {
        state.filterOptions.sources = []
      }
    },
    SET_LOCALES (state, value) {
      state.locales = value
    },
    SET_COUNTRIES (state, value) {
      state.countries = value
    },
    SET_SELECTED_COUNTRIES (state, value) {
      state.selectedCountries = value
    },
    SET_IS_SELECTED_COUNTRIES_UPDATED (state, value) {
      state.isSelectedCountriesUpdated = value
    },
    SET_IS_MACRO_UPDATED (state, value) {
      state.isMacroUpdated = value
    },
    SET_MACROS_LIST_MOSTLY_USED (state, value) {
      state.macrosListMostlyUsed = value
    },
    SET_MACROS_LIST_ALL (state, value) {
      state.macrosListAll = value
    },
    ADD_MACRO_TO_MACROS_LIST_ALL (state, value) {
      const targetMacro = state.macrosListAll.find(
        (macro) => macro.uuid === value.uuid
      )

      if (targetMacro) return
      
      state.macrosListAll.push(value)

      state.macrosListAll.sort((x, y) => {
        return (x.title.toLowerCase() < y.title.toLowerCase()) ? -1 : (x.title.toLowerCase() > y.title.toLowerCase()) ? 1 : 0
      })
    },
    UPDATE_MACRO (state, value) {
      const targetMacro = state.macrosListAll.find(
        (macro) => macro.uuid === value.uuid
      )

      if (targetMacro) {
        targetMacro.title = value.title
        targetMacro.text = value.text
      }

      state.macrosListAll.sort((x, y) => {
        return (x.title.toLowerCase() < y.title.toLowerCase()) ? -1 : (x.title.toLowerCase() > y.title.toLowerCase()) ? 1 : 0
      })
    },
    UPDATE_CONVERSATIONS_COUNT (state, { userId, count }) {
      const isCurrentConversation = state.currentConversation && state.currentConversation.creator.uuid === userId

      if (isCurrentConversation) {
        state.currentConversation.creator.conversation_count = count
      }
    },
    SET_ADMIN_STATUS (state, value) {
      state.adminStatus = value
    },
    SET_QUEUED_COUNT (state, value) {
      state.queuedCount = value
    },
    SET_ONLINE_ASSIGNEES (state, value) {
      state.onlineAssignees = value
    },
    SET_ASSIGNEES_MODAL_TYPE (state, value) {
      state.assigneesModalType = value
    },
    SET_SNOOZE_MODAL_TYPE (state, value) {
      state.snoozeModalType = value
    },
    SET_NOTES (state, value) {
      if (state.notesSort === 'oldest') {
        state.notes = value.reverse()

        return
      }

      state.notes = value
    },
    SET_NOTES_LOADING (state, value) {
      state.notesLoading = value
    },
    SET_NOTE_LOADING (state, value) {
      state.noteLoading = value
    },
    SET_NOTES_SORT (state, value) {
      state.notesSort = value
    },
    REVERSE_NOTES (state) {
      state.notes.reverse()
    },
    ADD_NOTE (state, value) {
      if (state.notesSort === 'oldest') {
        state.notes.unshift(value)

        return
      }

      state.notes.push(value)
    },
    DELETE_NOTE (state, value) {
      state.notes = state.notes.filter(
        (note) => note.uuid !== value
      )
    },
    ADD_RECENT_CONVERSATIONS (state, value) {
      state.recentConversations.push(...value)
    },
    REMOVE_RECENT_CONVERSATIONS (state) {
      state.recentConversations = []
    },
    SET_RECENT_CONVERSATIONS_LOADING (state, value) {
      state.recentConversationsLoading = value
    },
    SET_RECENT_CONVERSATIONS_PAGE (state, value) {
      state.recentConversationsPage = value
    },
    SET_RECENT_CONVERSATIONS_LAST_PAGE (state, value) {
      state.recentConversationsLastPage = value
    },
    SET_SUPPORT_TL (state, value) {
      state.isSupportTl = value
    },
    SET_USER_QUEUES (state, value) {
      state.userQueues = value
    },
    SET_USER_QUEUES_LOADING (state, value) {
      state.userQueuesLoading = value
    },
    UPDATE_USER_QUEUES (state, value) {
      for (const key in state.userQueues) {
        state.userQueues[key] = value.indexOf(key) !== -1
      }
    },
    SET_IS_NOT_MATCH_ACTIVE_FILTERS (state, value) {
      state.isNotMatchActiveFilters = value
    },
    TOGGLE_REFRESH_DASHBOARD_TABLE (state) {
      state.shouldRefreshDashboardTable = !state.shouldRefreshDashboardTable
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getConversationType ({ state }, { type }) {
      state.conversationType = (type === 0) ? 'chat' : 'ticket'
    },
    // eslint-disable-next-line no-empty-pattern
    showErrorMessage ({}, {errors = null}) {
      if (errors && errors.response && errors.response.data) {
        this._vm.alertError(errors.response.data.errors || errors.response.data.message)
      } else {
        this._vm.alertError('Something went wrong')
      }
    },
    runTimer ({ state }) {
      state.timerInterval = setInterval(() => {
        state.dateNow = new Date()
      }, 1000)
    },
    resetTimer ({ state }) {
      clearInterval(state.timerInterval)
      state.timerInterval = null
    },
    async getDefaultFilters ({ commit, dispatch }) {

      return this._vm.$httpChat
        .get('/api/admin/conversations/default-filters')
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('SET_FILTER_OPTIONS', response.data)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    resetConversations ({ commit }) {
      commit('REMOVE_CONVERSATIONS')
      commit('SET_CURRENT_CONVERSATION', null)
      commit('SET_USER_INFO', null)
      commit('SET_USER_DETAILS_INFO', null)
      commit('REMOVE_MESSAGES')
    },

    resetPages ({ commit }) {
      commit('SET_CONVERSATIONS_PAGE', 1)
      commit('SET_CONVERSATIONS_LAST_PAGE', 2)
      commit('SET_MESSAGES_PAGE', 1)
      commit('SET_MESSAGES_LAST_PAGE', 2)
    },

    async getConversations ({ state, commit, dispatch }, { queryConversationId = null }) {
      if (state.conversationsPage === 1) {
        dispatch('resetConversations')
      }

      if (state.conversationsLastPage >= state.conversationsPage) {
        commit('SET_CONVERSATIONS_LOADING', true)

        return this._vm.$httpChat
          .post('/api/admin/conversations/list', {
            page: state.conversationsPage,
            per_page: state.conversationsPerPage,
            sort: state.sort,
            filters: state.filterOptions
          })
          .then((response) => {
            if (!this._vm.handleResponseError(response)) {
              const { data, page, last_page, filters, queued_count, is_support_tl, user_queues } = response.data
              commit('ADD_CONVERSATIONS', data)
              commit('SET_QUEUED_COUNT', queued_count)
              commit('SET_SUPPORT_TL', is_support_tl)
              commit('SET_USER_QUEUES', user_queues)

              if (state.isSupportTl) {
                dispatch('getCountries')
              }

              if (state.conversationsPage === 1) {
                // Update filter options conversation on init load conversations
                commit('REMOVE_FILTER_OPTIONS')
                commit('SET_ASSIGNEE_OPTIONS', filters.assignee_users)
                commit('SET_PARTICIPANT_OPTIONS', filters.participant_users)
                commit('SET_SOURCES_OPTIONS', filters.sources)

                if (state.conversations.length && !state.currentConversation && !queryConversationId) {
                  // Open first conversation on init load conversations
                  commit('SET_CURRENT_CONVERSATION', state.conversations[0])
                  dispatch('loadConversation')
                }
              }
              commit('SET_CONVERSATIONS_PAGE', page + 1)
              commit('SET_CONVERSATIONS_LAST_PAGE', last_page)
            }
          })
          .catch((errors) => {
            dispatch('showErrorMessage', { errors })
          })
          .finally(() => {
            commit('SET_CONVERSATIONS_LOADING', false)
          })
      }
    },

    async getConversationsReload ({ state, commit, dispatch }) {

      return this._vm.$httpChat
        .post('/api/admin/conversations/list', {
          page: 1,
          per_page: state.conversationsPerPage,
          sort: state.sort,
          filters: state.filterOptions
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { data } = response.data
            commit('UPDATE_CONVERSATIONS', data)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getMessages ({ state, commit, dispatch }, { id }) {
      if (state.messagesPage === 1) {
        commit('SET_CURRENT_CONVERSATION_LOADING', true)
        commit('SET_CURRENT_CONVERSATION', null)
        commit('SET_USER_INFO', null)
        commit('SET_USER_DETAILS_INFO', null)
        commit('REMOVE_MESSAGES')
      }

      if (state.messagesLastPage >= state.messagesPage) {
        commit('SET_MESSAGES_LOADING', true)

        this._vm.$httpChat
          .post(`/api/admin/conversations/${id}/list`, {
            page: state.messagesPage,
            per_page: state.conversationsPerPage
          })
          .then((response) => {
            if (!this._vm.handleResponseError(response)) {
              const res = response.data

              if (state.messagesPage === 1) {
                commit('SET_CURRENT_CONVERSATION', res)
              }

              const { data, page, last_page } = res

              commit('SET_MESSAGES', data.reverse())

              commit('SET_MESSAGES_PAGE', page + 1)
              commit('SET_MESSAGES_LAST_PAGE', last_page)
            }
          })
          .catch((errors) => {
            dispatch('showErrorMessage', { errors })
          })
          .finally(() => {
            commit('SET_MESSAGES_LOADING', false)
            commit('SET_CURRENT_CONVERSATION_LOADING', false)
          })
      }
    },

    addMessageToConversationsList ({ state, dispatch }, { message }) {
      const targetConversation = state.conversations.find(
        (conversation) => conversation.uuid === message.conversation_uuid
      )

      if (targetConversation) {

        // Update message assignee
        if (message.assignee && state.currentConversation) {
          targetConversation.assignee = message.assignee

          if (targetConversation.uuid === state.currentConversation.uuid) {
            state.currentConversation.assignee = message.assignee
          }
        }

        // Set new message Date/Time
        targetConversation.latest_generic_message_sent_at = message.created_at

        // Sort conversation new message Date/Time
        // Is sort by oldest
        if (state.sort === 'oldest') {
          state.conversations.sort((x, y) => new Date(x.latest_generic_message_sent_at) - new Date(y.latest_generic_message_sent_at))
        } else {
          // Is sort by newest
          state.conversations.sort((x, y) => new Date(y.latest_generic_message_sent_at) - new Date(x.latest_generic_message_sent_at))
        }

        // Set status
        if (message.type === 'event.conversation_unsnoozed' || message.type === 'event.conversation_reopened') {
          targetConversation.status = 'open'
          targetConversation.snooze_until = null
        }

        if (message.type === 'event.conversation_snoozed') {
          targetConversation.status = 'snoozed'

          // Update Conversations List
          dispatch('updateConversationsListOnChangeStatus', { id: message.conversation_uuid, status: 'snoozed' })
        }

        if (message.type === 'event.conversation_closed') {
          targetConversation.status = 'closed'
        }

        // if ((message.type === 'event.assignee_updated') && (!message.assignee.name && !state.isSupportTl)) {
        //   dispatch('updateConversationsListOnUnassigned', { id: message.conversation_uuid })
        //
        //   return
        // }

        if (!message.type.includes('event') && message.type !== 'rating') {
          // Update message preview and time
          targetConversation.latest_message = message

          // Sort conversations
          if (message.type === 'message' || message.type === 'note') {
            
            if (state.sort === 'oldest') {

              // Remove from not last page
              if (state.conversationsPage <= state.conversationsLastPage) {
                state.conversations.pop()
              }
            }
            
          }
        }
      }
    },

    updateConversationAssignee ({ state, dispatch }, { conversation }) {
      const targetConversation = state.conversations.find(
        (conversationItem) => conversationItem.uuid === conversation.uuid
      )

      if (targetConversation) {
        // Remove item from conversation list if specific assignee filter is active
        const isNotCurrentAssigneeFilter = state.filterOptions.assignee_user_ids.length && state.filterOptions.assignee_user_ids.indexOf(conversation.assignee.id) === -1

        if (isNotCurrentAssigneeFilter) {
          
          state.conversations = state.conversations.filter(
            (conversationItem) => conversationItem.uuid !== conversation.uuid
          )

          if (state.currentConversation && state.currentConversation.uuid === conversation.uuid) {
            // Open first conversation
            dispatch('openFirstConversation')
          }

          return
        }

        // Re-assignee conversations
        if (conversation.assignee) {
          targetConversation.assignee = conversation.assignee

          if (state.currentConversation && state.currentConversation.uuid === targetConversation.uuid) {
            state.currentConversation.assignee = conversation.assignee
          }
        }
      }
    },

    updateConversationsListOnUnassigned ({ state, dispatch }, { id }) {
      state.conversations = state.conversations.filter(
        (conversationItem) => conversationItem.uuid !== id
      )

      // Open first conversation
      if (state.currentConversation && state.currentConversation.uuid === id) {
        dispatch('openFirstConversation')
      }
    },

    async sendMessage ({ commit, state, dispatch }, { id, text, attachments, translate, type, macro_uuid, loader }) {
      // Immediately display message in chat
      commit('SET_SOCKET_MESSAGE_LOADING', true)
      const message = {
        type,
        text,
        translated_text: translate ? text : '',
        created_at: new Date(),
        attachments: attachments ? attachments : [],
        is_support_message: true
      }
      commit('ADD_MESSAGE', message)

      if (text || attachments.length) {
        // Set message in conversation list
        dispatch('addMessageToConversationsList', { message: {...message, conversation_uuid: state.currentConversation.uuid } })
      }

      await this._vm.$nextTick()

      commit('SET_SOCKET_MESSAGE_LOADING', false)

      // Send request
      if (loader) {
        commit('SET_MESSAGE_LOADING', true)
      }

      const formData = new FormData()
      formData.set('text', text)
      formData.set('translate', translate)
      formData.set('type', type)

      if (type === 'message' && macro_uuid?.length) {
        macro_uuid.map((uuid) => {
          formData.append('macro_uuid[]', uuid)
        })
      }

      attachments.map((file) => {
        formData.append('attachments[]', file)
      })

      return this._vm.$httpChat
        .post(`/api/admin/conversations/${id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { uuid, latest_message } = response.data

            if (state.currentConversation && uuid === state.currentConversation.uuid) {
              commit('SET_SOCKET_MESSAGE_LOADING', true)
              commit('UPDATE_LAST_MESSAGE', latest_message)
            }
          } else {
            // Show failed message error
            message.error = true
            commit('UPDATE_LAST_MESSAGE', message)

            if (text || attachments.length) {
              // Set message in conversation list
              dispatch('addMessageToConversationsList', { message: {...message, conversation_uuid: state.currentConversation.uuid } })
            }
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })

          // Show failed message error
          message.error = true
          commit('UPDATE_LAST_MESSAGE', message)

          if (text || attachments.length) {
            // Set message in conversation list
            dispatch('addMessageToConversationsList', { message: {...message, conversation_uuid: state.currentConversation.uuid } })
          }
        })
        .finally(() => {
          if (loader) {
            commit('SET_MESSAGE_LOADING', false)
          }
          commit('SET_SOCKET_MESSAGE_LOADING', false)
        })
    },

    async deleteMessage ({ commit, dispatch }, { conversationId, messageId }) {

      return this._vm.$httpChat
        .delete(`/api/admin/conversations/${conversationId}/messages/${messageId}`)
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('INDICATE_DELETED_MESSAGE', { conversationId, messageId })
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    loadConversation ({ state, dispatch, commit }) {
      commit('SET_MESSAGES_PAGE', 1)
      commit('SET_MESSAGES_LAST_PAGE', 2)
      dispatch('getMessages', { id: state.currentConversation.uuid })
    },

    async openFirstConversation ({ state, dispatch, commit }) {
      // Reset Current Conversation
      commit('SET_CURRENT_CONVERSATION', null)
      commit('REMOVE_MESSAGES')

      await this._vm.$nextTick()

      // Open first conversation
      if (state.conversations.length) {
        commit('SET_CURRENT_CONVERSATION', state.conversations[0])
        dispatch('loadConversation')
      }
    },

    updateConversationsListOnChangeStatus ({ state, dispatch, commit }, { id, status }) {
      if (state.currentConversation && state.currentConversation.uuid === id) {
        commit('SET_CURRENT_CONVERSATION_STATUS', status)
      }

      // Prevent remove if specific status filter is active
      if (state.filterOptions.status.length && state.filterOptions.status.indexOf(status) !== -1) return

      // Remove closed/snoozed/reopened conversation from list
      state.conversations = state.conversations.filter(
        (conversation) => conversation.uuid !== id
      )

      // Open first conversation
      if (state.currentConversation && state.currentConversation.uuid === id) {
        dispatch('openFirstConversation')
      }
    },

    updateMultipleConversationsListOnChangeStatus ({ dispatch }, { ids, status }) {

      ids.forEach((id) => {
        dispatch('updateConversationsListOnChangeStatus',  { id, status })
      })

    },

    async addMessageFromSocket ({ state, commit, dispatch }, { message, name }) {
      const isCurrentConversation = state.currentConversation && state.currentConversation.uuid === message.conversation_uuid

      // Put only not current user messages
      if ((message.author.name !== name || message.type.match('event')) && isCurrentConversation) {
        
        const targetMessage = state.messages.find(
          (item) => item.uuid === message.uuid
        )
        
        if (!targetMessage) {
          commit('SET_SOCKET_MESSAGE_LOADING', true)
          commit('ADD_MESSAGE', message)

          await this._vm.$nextTick()

          commit('SET_SOCKET_MESSAGE_LOADING', false)
        }
      }

      // Set message in conversation list
      dispatch('addMessageToConversationsList', { message })

      // Prevent play sound if specific assignee filter is active
      if (state.filterOptions.assignee_user_ids.length && state.filterOptions.assignee_user_ids.indexOf(message.assignee.id) === -1) return

      // Play notification sound
      if (message.assignee.name === name && !message.type.match('event') && !isCurrentConversation) {
        dispatch('playSound', { source: state.notificationSound })
      }
    },

    deleteMessageFromSocket ({ commit }, { message }) {
      commit('INDICATE_DELETED_MESSAGE', { conversationId: message.conversation_uuid, messageId: message.uuid })
    },

    seenMessageFromSocket ({ commit }, { message }) {
      commit('SEEN_MESSAGE', message)
    },

    checkMatchActiveFilters ({ state, commit, dispatch }, { conversation }) {
      // Prevent add conversation if specific assignee filter is active
      const isNotMatchAssignee = state.filterOptions.assignee_user_ids.length && state.filterOptions.assignee_user_ids.indexOf(conversation.assignee.id) === -1

      // Prevent add conversation if specific status filter is active
      const isNotMatchStatus = state.filterOptions.status.length && state.filterOptions.status.indexOf(conversation.status) === -1

      const isNotMatchOpenStatus = !state.filterOptions.status.length && (conversation.status === 'snoozed' || conversation.status === 'closed')

      // Prevent add conversation if specific app_external_id filter is active
      const isNotMatchApp = state.filterOptions.app_external_id.length && state.filterOptions.app_external_id.indexOf(conversation.creator.app_external_id) === -1

      // Prevent add conversation if specific sources filter is active
      dispatch('getConversationType', { type: conversation.type })
      const isNotMatchSource = state.filterOptions.sources.length && state.filterOptions.sources.indexOf(state.conversationType) === -1

      // Prevent add conversation if specific user_id filter is active
      const isNotMatchUser = state.filterOptions.user_id && state.filterOptions.user_id !== conversation.creator.ps_user_id

      // Prevent add conversation if specific rating filter is active
      const isNotMatchRating = state.filterOptions.rating.length && state.filterOptions.rating.indexOf(conversation.rating) === -1

      commit('SET_IS_NOT_MATCH_ACTIVE_FILTERS', isNotMatchAssignee || isNotMatchStatus || isNotMatchOpenStatus || isNotMatchApp || isNotMatchSource || isNotMatchUser || isNotMatchRating)
    },

    addConversationFromSocket ({ commit, state, dispatch }, { conversation, name, status }) {
      // Update Conversations List
      dispatch('updateConversationsListOnChangeStatus', { id: conversation.uuid, status: conversation.status })

      dispatch('checkMatchActiveFilters', { conversation })
      if (state.isNotMatchActiveFilters) return

      const targetConversation = state.conversations.find(
        (conversationItem) => conversationItem.uuid === conversation.uuid
      )

      // If conversations doesn't exist in list
      if (!targetConversation) {
        commit('ADD_NEW_CONVERSATION_TO_LIST', conversation)
      }

      // Open first conversation
      if (!state.currentConversation) {
        dispatch('openFirstConversation')
      }

      // Play notification sound
      if (conversation.assignee.name === name && status === 'created') {
        dispatch('playSound', { source: state.notificationSound })
      }
    },

    reassignedMessageFromSocket ({ state, dispatch, commit }, { conversation, name }) {
      // Update message in conversation list
      dispatch('updateConversationAssignee', { conversation })
      
      const assigneeName = conversation.assignee.name

      const targetConversation = state.conversations.find(
        (conversationItem) => conversationItem.uuid === conversation.uuid
      )

      // Check unassigned filter for support TL
      const isUnassigned = (state.filterOptions.assignee_user_ids.length && state.filterOptions.assignee_user_ids.indexOf('unassigned') !== -1) && !assigneeName

      if (isUnassigned && !targetConversation) {
        commit('ADD_NEW_CONVERSATION_TO_LIST', conversation)

        return
      }

      // Prevent show non-assigned conversation for support TL
      if (!assigneeName && !state.isSupportTl) {
        dispatch('updateConversationsListOnUnassigned', { id: conversation.uuid })

        return
      }

      dispatch('checkMatchActiveFilters', { conversation })
      if (state.isNotMatchActiveFilters) return

      // If conversations doesn't exist in list
      if (!targetConversation) {
        commit('ADD_NEW_CONVERSATION_TO_LIST', conversation)
      }

      // Play notification sound
      if (assigneeName === name) {
        dispatch('playSound', { source: state.notificationSound })
      }
    },

    queuedCountFromSocket ({  commit }, { queuedCount }) {
      commit('SET_QUEUED_COUNT', queuedCount)
    },

    async snoozeConversation ({ dispatch }, { id, snooze }) {

      return await this._vm.$httpChat
        .put(`/api/admin/conversations/${id}/snooze`, {
          snooze
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { uuid, status } = response.data

            dispatch('updateConversationsListOnChangeStatus', { id: uuid, status })
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async snoozeListConversations ({ state, dispatch, commit }, { conversation_uuids, snooze }) {
      
      const filteredIds = state.conversations.filter((conversation) => {
        return conversation_uuids.indexOf(conversation.uuid) !== -1 && conversation.status !== 'closed'
      }).map((item) => item.uuid)

      if (!filteredIds.length) {
        commit('RESET_SELECTED_CONVERSATIONS')

        return
      }

      return await this._vm.$httpChat
        .put('/api/admin/conversations/snooze', {
          conversation_uuids: filteredIds,
          snooze
        })
        .then(() => {
          dispatch('updateMultipleConversationsListOnChangeStatus', { ids: filteredIds, status: snooze ? 'snoozed' : 'open' })

          commit('RESET_SELECTED_CONVERSATIONS')
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async reopenConversation ({ dispatch }, { id }) {

      return await this._vm.$httpChat
        .put(`/api/admin/conversations/${id}/reopen`)
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { uuid, status } = response.data

            dispatch('updateConversationsListOnChangeStatus', { id: uuid, status })
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async reopenConversations ({ dispatch, commit }, { conversation_uuids }) {

      return await this._vm.$httpChat
        .put('/api/admin/conversations/reopen', {
          conversation_uuids
        })
        .then(() => {
          dispatch('updateMultipleConversationsListOnChangeStatus', { ids: conversation_uuids, status: 'open' })

          commit('RESET_SELECTED_CONVERSATIONS')
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async closeConversation ({ dispatch }, { id }) {

      return await this._vm.$httpChat
        .put(`/api/admin/conversations/${id}/close`)
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { uuid, status } = response.data

            dispatch('updateConversationsListOnChangeStatus', { id: uuid, status })
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async closeConversations ({ dispatch, commit }, { conversation_uuids }) {

      return await this._vm.$httpChat
        .put('/api/admin/conversations/close', {
          conversation_uuids
        })
        .then(() => {
          dispatch('updateMultipleConversationsListOnChangeStatus', { ids: conversation_uuids, status: 'closed' })

          commit('RESET_SELECTED_CONVERSATIONS')
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getLocales ({ commit, dispatch }) {
      return await this._vm.$httpChat
        .get('/api/admin/locales')
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('SET_LOCALES', response.data)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async selectConversationLanguage ({ dispatch }, { id, language_code }) {

      return await this._vm.$httpChat
        .put(`/api/admin/conversations/${id}/language-code`, {
          language_code
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            dispatch('loadConversation')
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getCountries ({ commit, dispatch }) {
      return await this._vm.$http
        .get('/api/chat/available-countries')
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const countries = response.data
            commit('SET_COUNTRIES', countries)

            const selectedCountries = countries.filter((country) => country.is_selected)
            const selectedCountryCodes = selectedCountries.map((country) => country.code)
            commit('SET_SELECTED_COUNTRIES', selectedCountryCodes)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async selectChatAvailableCountries ({ dispatch, commit }, { codes }) {

      return await this._vm.$http
        .put('/api/chat/available-countries', {
          codes
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { selected_codes } = response.data

            commit('SET_SELECTED_COUNTRIES', selected_codes)
            commit('SET_IS_SELECTED_COUNTRIES_UPDATED', true)

            this._vm.alertSuccess('Available countries are successfully updated!')
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
        .finally(() => {
          commit('SET_IS_SELECTED_COUNTRIES_UPDATED', false)
        })
    },

    async getMacros ({ commit, dispatch }) {
      return await this._vm.$httpChat
        .post('/api/admin/macros/list')
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const  { data, mostly_used } = response.data
            commit('SET_MACROS_LIST_ALL', data)
            commit('SET_MACROS_LIST_MOSTLY_USED', mostly_used)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async createMacro ({ commit, dispatch }, { title, text }) {
      return await this._vm.$httpChat
        .post('/api/admin/macros', {
          title,
          text
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('ADD_MACRO_TO_MACROS_LIST_ALL', response.data)
            commit('SET_IS_MACRO_UPDATED', true)

            this._vm.alertSuccess(`Macro ${title} successfully created`)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
        .finally(() => {
          commit('SET_IS_MACRO_UPDATED', false)
        })
    },

    async updateMacro ({ commit, dispatch }, { macro_uuid, title, text }) {
      return await this._vm.$httpChat
        .put(`/api/admin/macros/${macro_uuid}`, {
          title,
          text
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('UPDATE_MACRO', response.data)
            commit('SET_IS_MACRO_UPDATED', true)

            this._vm.alertSuccess(`Macro ${title} successfully updated`)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
        .finally(() => {
          commit('SET_IS_MACRO_UPDATED', false)
        })
    },

    async addMacroFromSocket ({ commit }, { macro }) {
      commit('ADD_MACRO_TO_MACROS_LIST_ALL', macro)
    },

    async updateMacroFromSocket ({ commit }, { macro }) {
      commit('UPDATE_MACRO', macro)
    },

    async updateConversationsCountFromSocket ({ commit }, { userId, count }) {
      commit('UPDATE_CONVERSATIONS_COUNT', { userId, count })
    },

    async getUserInfo ({ state, commit, dispatch }) {
      await this._vm.$nextTick()

      const appId =  state.currentConversation.creator.app_external_id
      const userId =  state.currentConversation.creator.external_id

      return await this._vm.$http
        .get(`/api/apps/${appId}/users/${userId}/chat-info`)
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('SET_USER_INFO', response.data)

            commit('SET_USER_DETAILS_INFO', response.data.user)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getAdminStatus ({ commit, dispatch }) {

      return await this._vm.$http
        .get('/api/chat/chat-status')
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { status } = response.data

            commit('SET_ADMIN_STATUS', status)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async setAdminStatus ({ commit, dispatch }, { status }) {

      return await this._vm.$http
        .put('/api/chat/chat-status', {
          status
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const { status } = response.data

            commit('SET_ADMIN_STATUS', status)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async setQueues ({ commit, dispatch, state }) {
      commit('SET_USER_QUEUES_LOADING', true)

      return await this._vm.$httpChat
        .put('/api/admin/queues', {
          ...state.userQueues
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('SET_USER_QUEUES', response.data)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
        .finally(() => {
          commit('SET_USER_QUEUES_LOADING', false)
        })
    },

    async getOnlineAssignees ({ commit, dispatch }) {

      return await this._vm.$http
        .get('/api/chat/assignees')
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const res = response.data

            commit('SET_ONLINE_ASSIGNEES', res)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async setChatAssignee ({ dispatch }, { id, admin_id }) {

      return await this._vm.$httpChat
        .put(`/api/admin/conversations/${id}/assignee`, {
          admin_id
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const conversation = response.data

            // Update message in conversation list
            dispatch('updateConversationAssignee', { conversation })
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async setChatListAssignee ({ dispatch, commit }, { conversation_uuids, admin_id }) {
      
      return await this._vm.$httpChat
        .put('/api/admin/conversations/assignee', {
          admin_id,
          conversation_uuids
        })
        .then(() => {
          commit('RESET_SELECTED_CONVERSATIONS')
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getAppUserDetails ({ getters, commit, dispatch }) {
      if (!getters.appName) return

      await this._vm.$http
        .get(`/api/${ getters.appName }/user/${ getters.appUserId }`)
        .then((response) => {
          commit('SET_INTERCOM_BANNED', response.data.intercom_banned)
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getStreaks ({ getters, commit, dispatch }) {
      if (!getters.appName) return

      await this._vm.$http
        .get(`/api/${ getters.appName }/user/${ getters.appUserId }/streaks`)
        .then((response) => {
          const { streaks, last_renewal } = response.data

          commit('SET_STREAKS', streaks)
          commit('SET_STREAK_LAST_RENEWAL', last_renewal)
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getNotes ({ commit, getters, dispatch }) {
      commit('SET_NOTES_LOADING', true)

      return this._vm.$httpChat
        .get(`/api/admin/apps/${getters.externalAppId}/users/${getters.externalUserId}/notes`)
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('SET_NOTES', response.data)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
        .finally(() => {
          commit('SET_NOTES_LOADING', false)
        })
    },

    async sendNote ({ commit, getters, dispatch }, { text }) {
      commit('SET_NOTE_LOADING', true)

      return this._vm.$httpChat
        .post(`/api/admin/apps/${getters.externalAppId}/users/${getters.externalUserId}/notes`, {
          text
        })
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            commit('ADD_NOTE', response.data)
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
        .finally(() => {
          commit('SET_NOTE_LOADING', false)
        })
    },

    async deleteNote ({ commit, getters, dispatch }, { noteId }) {
      return this._vm.$httpChat
        .delete(`/api/admin/apps/${getters.externalAppId}/users/${getters.externalUserId}/notes/${noteId}`)
        .then((response) => {
          if (!this._vm.handleResponseError(response)) {
            const {status} = response

            if (status === 200) {
              commit('DELETE_NOTE', noteId)
            }
          }
        })
        .catch((errors) => {
          dispatch('showErrorMessage', { errors })
        })
    },

    async getRecentConversations ({ commit, state, getters }) {
      if (state.recentConversationsLastPage >= state.recentConversationsPage) {
        commit('SET_RECENT_CONVERSATIONS_LOADING', true)

        return this._vm.$httpChat
          .post('/api/admin/conversations/list', {
            page: state.recentConversationsPage,
            per_page: state.conversationsPerPage,
            sort: 'newest',
            filters: {
              user_id: getters.userId || state.currentConversation.creator.uuid,
              status: ['open', 'snoozed', 'closed'],
              assignee_user_ids: [],
              participant_user_ids: [],
              app_external_id: [],
              sources: [],
              start_time: null,
              rating: []
            }
          })
          .then((response) => {
            if (!this._vm.handleResponseError(response)) {
              const { data, page, last_page} = response.data
              commit('ADD_RECENT_CONVERSATIONS', data)
                
              commit('SET_RECENT_CONVERSATIONS_PAGE', page + 1)
              commit('SET_RECENT_CONVERSATIONS_LAST_PAGE', last_page)
            }
          })
          .catch((errors) => {
            this._vm.alertError(errors.response.data)
          })
          .finally(() => {
            commit('SET_RECENT_CONVERSATIONS_LOADING', false)
          })
      }
    },

    clearSound ({ state }) {
      if (state.currentlyPlayingAudio) {
        state.currentlyPlayingAudio.pause()
        state.currentlyPlayingAudio = null
      }
    },
    
    playSound ({ state, dispatch }, { source }) {
      if (state.adminStatus === 1) {
        dispatch('clearSound')

        state.currentlyPlayingAudio = new Audio(`${state.storageUrl}${source}`)
        state.currentlyPlayingAudio.play()
      }
    }
  }
}
