export default {
  PSA_BUYERS: {
    path: '/psa-buyers',
    name: 'psa_buyers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/prime_sample/Buyers')
  },
  PSA_SURVEYS: {
    path: '/psa-surveys',
    name: 'psa-surveys',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/prime_sample/Surveys')
  },
  PSA_MONTHLY_INVOICING: {
    path: '/psa-monthly-invoicing',
    name: 'psa-monthly-invoicing',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/prime_sample/Invoicing/ClientsMonthlyInvoicing.vue')
  },
  PSA_SURVEY_PO_INVOICING: {
    path: '/psa-survey-po-invoicing',
    name: 'psa-survey-po-invoicing',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/prime_sample/Invoicing/SurveyPoInvoicing.vue')
  },
  PSA_POPULAR_QUESTIONS: {
    path: '/psa-popular-questions',
    name: 'psa_popular_questions',
    meta: {
      requiresAuth: true
    },
    component: () => import('@ps_views/prime_sample/Question/PopularQuestions.vue')
  }
}
