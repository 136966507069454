<template>
  <div v-if="name" class="buyer-item">
    <router-link
        v-if="id && canAccess('supplier_buyer_details')"
        :to="{
          name: 'supplier_buyer_details',
          params: { supplierBuyerId: id },
        }"
    >
      {{ onlyShowName ? name : id + ' - ' + name + ` (${buyerId})` }}
    </router-link>
    <span v-else>{{ onlyShowName ? name : id + ' - ' + name + ` (${buyerId})` }}</span>
  </div>
  <div v-else>
    <router-link
        v-if="id && canAccess('supplier_buyer_details')"
        :to="{
          name: 'supplier_buyer_details',
          params: { supplierBuyerId: id },
        }"
    >
      {{ id }}
    </router-link>
    <span v-else>{{ id }}</span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    buyerId: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    onlyShowName: {
      type: Boolean,
      default: false
    }
  }
}
</script>
