<template>
  <div>
    <h3 v-if="!isDisplayInline">Actions</h3>
    <div class="d-flex flex-wrap" :class="{'align-items-start flex-column': !isDisplayInline}" style="gap: 5px;">
      <button
          class="btn btn-danger"
          @click="openUserBanModal(2)"
          type="button"
      >
        Ban
      </button>
      <button
          class="btn text-white user-status-3 text-nowrap"
          @click="openUserBanModal(3)"
          type="button"
      >
        Shadow Ban
      </button>
      <button
          class="btn text-white user-status-6 text-nowrap"
          @click="openUserBanModal(6)"
          type="button"
      >
        On Hold
      </button>
      <button v-if="user.status !== 4"
          class="btn btn-secondary"
          @click="openReviewConfirmModal"
          type="button"
      >
        Review
      </button>
      <button v-if="isCanUpdateGoodwillCredit"
              class="btn btn-success"
              @click="openGoodwillCreditModal"
              type="button"
      >
        Credit
      </button>
      <button
          class="btn btn-primary text-nowrap"
          @click="openLiveQuerySurveysModal"
          type="button"
      >
        Live Query Surveys
      </button>
    </div>

    <UserBanModal :isNeedApproval="false" :userId="user.id" :status="currentStatusBan" ref="user_ban_form" @added="addedUserBanned" />
    <ConfirmReviewModal ref="confirm_review_modal" :userId="user.id" @changed="changeToReviewStatus" />
    <GoodwillCreditModal ref="goodwill_credit_modal" :exchangeRate="exchangeRate" :currencyName="currencyName" :userId="user.id" />
    <LiveQuerySurveysModal ref="liveQuerySurveysModal" :user="user" :userId="user.id" />
  </div>
</template>

<script>
import ConfirmReviewModal from '@/views/users/UserDetails/ConfirmReviewModal.vue'
import UserBanModal from '@/views/users/UserDetails/UserBanModal.vue'
import GoodwillCreditModal from '@/views/users/UserDetails/GoodwillCreditModal.vue'
import LiveQuerySurveysModal from '@/views/users/UserDetails/LiveQuerySurveysModal.vue'

export default {
  name: 'UserActionsBox',
  components: {LiveQuerySurveysModal, GoodwillCreditModal, UserBanModal, ConfirmReviewModal},
  props: {
    user: {
      type: Object,
      required: true
    },
    can_trigger_verification: {
      type: Boolean
    },
    phone_verifications: {
      type: Array
    },
    isDisplayInline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      exchangeRate: null,
      currencyName: 'Points',
      currentStatusBan: 2
    }
  },
  computed: {
    isCanUpdateGoodwillCredit () {
      const currentUserId = this.$store.state.auth.AppActiveAdmin.id

      return [1, 26].includes(currentUserId)
    }
  },
  methods: {
    openUserBanModal (status) {
      this.currentStatusBan = status
      this.getUserBanFormVue().showForm()
    },
    openReviewConfirmModal () {
      this.getConfirmReviewModalVue().showForm()
    },
    openGoodwillCreditModal () {
      this.showLoading()
      this.$http.get(`/api/apps/${this.user.app_id}/exchange-rate`)
        .then((response) => {
          this.hideLoading()

          this.exchangeRate = response.data.exchange_rate
          this.currencyName = response.data.currency_name
          this.getGoodwillCreditModalVue().showForm()
        }).catch((errors) => {
          this.hideLoading()
          if (errors && errors.response && errors.response.data) {
            this.alertError(errors.response.data.errors)
            this.alertError(errors.response.data.errors)
          }
        })
    },
    openLiveQuerySurveysModal () {
      this.$refs.liveQuerySurveysModal.showModal()
    },
    getUserBanFormVue () {
      return this.$refs.user_ban_form
    },
    getConfirmReviewModalVue () {
      return this.$refs.confirm_review_modal
    },
    getGoodwillCreditModalVue () {
      return this.$refs.goodwill_credit_modal
    },
    addedUserBanned (data) {
      this.$emit('update:user', data.response)
    },
    changeToReviewStatus (data) {
      const newPhoneVerifications = [
        {phone: null, verified: false},
        ...this.phone_verifications
      ]

      this.$emit('update:can_trigger_verification', false)
      this.$emit('update:phone_verifications', newPhoneVerifications)
      this.$emit('update:user', data.response)
    }
  }
}
</script>
