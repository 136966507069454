<template>
  <div>
    <b-modal
        :id="modalUUid"
        :ref="modalUUid"
        title="Add goodwill credit"
        :hide-footer="true"
    >
      <BaseForm
          ref="main_form"
          :settings="goodwillCreditFormSettings"
          @success="successAddedUserBan"
          @cancel="$bvModal.hide(modalUUid)"
          v-slot="{ save, cancel }"
      >
        <b-row class="mb-1">
          <b-col cols="3">Publisher Payout</b-col>
          <b-col cols="6">
            <div class="d-flex align-items-center" style="gap: 5px;">
              <FormElement
                  ref="publisherPayout"
                  :elementSettings="goodwillCreditFormSettings.formElements.publisher_payout"
                  :formSettings="goodwillCreditFormSettings"
              />
              $
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="3">Exchange Rate</b-col>
          <b-col cols="9">
            $1 USD = {{ exchangeRate }} {{ currencyName }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="3">User Reward</b-col>
          <b-col cols="6">
            <div class="d-flex align-items-center" style="gap: 5px;">
              <FormElement
                  ref="userReward"
                  :elementSettings="goodwillCreditFormSettings.formElements.user_reward"
                  :formSettings="goodwillCreditFormSettings"
              />
              {{ currencyName }}
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="3">Reference</b-col>
          <b-col cols="9">
            <FormElement
                ref="reference"
                :elementSettings="goodwillCreditFormSettings.formElements.reference"
                :formSettings="goodwillCreditFormSettings"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" @click="save()" class="mr-1">
              Save
            </b-button>
            <b-button variant="warning" @click="cancel()"
            >Cancel</b-button
            >
          </b-col>
        </b-row>
      </BaseForm>
    </b-modal>
  </div>
</template>

<script>
export default {
  props:{
    userId: {
      required: true,
      type: Number
    },
    exchangeRate: {
      required: true
    },
    currencyName: {
      type: String,
      default: 'Points'
    }
  },
  data () {
    const _this = this

    return {
      modalUUid: `user-goodwill-credit-modal${ this.userId }`,
      goodwillCreditFormSettings: {
        addUrl: `/api/user/${  this.userId  }/goodwill-credit`,
        primaryKey: 'id',
        formElements: {
          publisher_payout: {
            type: 'input',
            sub_type: 'number',
            defaultValue: 0,
            rules: ['required'],
            value: 0,
            errorClass: 'input-error',
            errorMessage: 'Publisher Payout is invalid!',
            isValidated: false,
            isError: false,
            post_param: 'payout',
            event: {
              input ($event, elementSettings, formSettings) {
                const userReward = elementSettings.value * _this.exchangeRate
                formSettings.formElements.user_reward.value = (userReward % 1 === 0) ? userReward.toFixed(0) : userReward.toFixed(2)
              }
            },
            validate (elementSettings, formSettings) {
              return elementSettings.value <= 2
            }
          },
          user_reward: {
            type: 'input',
            sub_type: 'number',
            defaultValue: 0,
            rules: ['required'],
            value: 0,
            errorClass: 'input-error',
            errorMessage: 'User Reward is invalid!',
            isValidated: false,
            isError: false,
            post_param: 'user_reward',
            event: {
              input ($event, elementSettings, formSettings) {
                const pbulisherPayout = elementSettings.value / _this.exchangeRate
                formSettings.formElements.publisher_payout.value = (pbulisherPayout % 1 === 0) ? pbulisherPayout.toFixed(0) : pbulisherPayout.toFixed(2)
              }
            }
          },
          reference: {
            type: 'input',
            defaultValue: '',
            rules: ['required'],
            post_param: 'reference',
            value: '',
            errorClass: 'input-error',
            errorMessage: 'Reference is required!',
            isValidated: false,
            place_holder: 'Reference',
            isError: false
          }
        },
        vueContainer: this
      }
    }
  },
  methods: {
    showForm () {
      this.$bvModal.show(this.modalUUid)

      this.$nextTick(() => {
        this.getFormVue().clearForm()
      })
    },
    successAddedUserBan (data) {
      this.$emit('added', data)
      this.$bvModal.hide(this.modalUUid)
    },
    getFormVue () {
      return this.$refs.main_form
    }
  },
  watch: {
    'goodwillCreditFormSettings.formElements.status.value' (value) {
      this.goodwillCreditFormSettings.formElements.ban_indefinite.disabled = value === 3
      this.goodwillCreditFormSettings.formElements.ban_indefinite.value = value === 3
    }
  }
}
</script>

<style>
</style>
