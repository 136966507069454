import pagesAndPermissions from '@/main/pages/pagesAndPermissions'
import primeOpinionPagesAndPermissions from '@/main/pages/primeOpinionPages'
import fiveSurveysPagesAndPermissions from '@/main/pages/fiveSurveysPages'
import primeSamplePagesAndPermissions from '@/main/pages/primeSamplePages'
import heyCashPagesAndPermissions from '@/main/pages/heyCashPages'

function getPageAndPermission (pagesAndPermissions) {
  let returnData = []
  for (const item of pagesAndPermissions) {
    if (item.children) {
      returnData = [...returnData, ...getPageAndPermission(item.children)]
    } else {
      returnData.push(item)
    }
  }
  return returnData
}

export default getPageAndPermission([
  ...pagesAndPermissions,
  ...primeOpinionPagesAndPermissions,
  ...fiveSurveysPagesAndPermissions,
  ...primeSamplePagesAndPermissions,
  ...heyCashPagesAndPermissions
])
